import { ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ColDef, GridApi, IRowNode, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { SacCotizacionService } from 'app/services/acuerdos-comerciales/sacCotizacion.service';
import { format, parseISO } from 'date-fns';
import { Observable, Subscription, firstValueFrom, of, tap } from 'rxjs';
import { ConfigButtonAction, ConfigButtonAgGrid, EnumButtonType } from 'ngx-sumax-erp-component';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SciAccion } from '~models/config';
import { SacCotizacionTipoOperador } from '~models/maestros-sync/maestros/SacCotizacionTipoOperador';
import { IconAgGridRenderComponent } from '~shared/components/icon-ag-grid-render/icon-ag-grid-render.component';
import { SolicitudCotizacionDialogComponent } from './solicitud-cotizacion-dialog/solicitud-cotizacion-dialog.component';
import { CellEstadoRenderComponent } from '~shared/components/cell-estado-render/cell-estado-render.component';
import { ConfigFilter } from './filtro-cotizacion-por-criterio-busqueda/filtro-cotizacion-por-criterio-busqueda.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DateUtil } from '~shared/utils/DateUtil';
import { EnumReferenceRoutingFilterControl } from '~models/Util/EnumReferenceControlRoutingFilterControl';
import { EnumAccion } from '~shared/enums/EnumAccion';
import { SearchSacCotizacion } from '~models/acuerdos-comerciales/custom/SearchSacCotizacion';
import { StoreService } from '~shared/services/store.service';
import { EnumPermissionDefault } from '~shared/enums/EnumPermission';
import { NotificationService } from 'app/services/notification.service';
import { DialogData } from '~shared/interface/DialogData';
import { VerCotiResumenComponent } from '../../common/ver-coti-resumen/ver-coti-resumen.component';
import { SdoPrinterService } from 'app/services/doc-oper/sdoPrinter.service';
import { PrintTipoDocumentoDialogComponent } from './print-tipo-documento-dialog/print-tipo-documento-dialog.component';
import { FiltroPorReferenciaService } from './filtro-por-referencia.service';
import { hideLoading, showLoading } from '~shared/utils/LoadingUtil';
import { EnumNumeroCotizacion } from '~shared/enums/EnumNumeroCotizacion';
import { SdoTipoDocumentoService } from 'app/services/doc-oper/sdoTipoDocumento';
import { SdoTipoDocumento } from '~models/doc-oper/SdoTipoDocumento';
import { CotizacionService } from '../cotizacion.service';
import { SdoPrinterCustom } from '~models/doc-oper/SdoPrinterCustom';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store';
import { currentUser } from '@core/store/selectors/auth.selectors';
import { AperturaOrdenDialogComponent } from './apertura-orden-dialog/apertura-orden-dialog.component';
import { SacCotizacionTipoOperadorService } from 'app/services/acuerdos-comerciales/sacCotizacionTipoOperador.service';
import { EnumTipoOperador } from '~shared/enums/EnumTipoOperador';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';
import { RechazarCotizacionDialogComponent } from './rechazar-cotizacion-dialog/rechazar-cotizacion-dialog.component';
import { parseDecimalAndCommaCustom } from '~shared/utils/MathUtil';
import { VariableGlobalCotizacionService } from '../cotizacion-constantes.service';
import { ConfigObjectDialog, ModalUtil } from '~shared/utils/ModalUtil';
import { EnumChanelAppSync } from '~shared/enums/EnumChanelAppSync';

interface ValueGetterParamsCustom extends ValueGetterParams {
	data: SacCotizacion;
}

export const constHeadersListaCotizacion = {
	_NUMERO: 'Número',
	_FECHA: 'Fecha',
	_ESTADO: 'Estado',
	_TIPOOPERACION: 'Tipo operac.',
	_VIATRANSPORTE: 'Via transp.',
	_TIPOFLETE: 'Tipo de flete',
	_OPERADOR: 'Operador',
	_INCOTERM: 'Incoterm',
	_CLIENTE: 'Cliente',
	_ORIGEN: 'Origen',
	_DESTINO: 'Destino',
	_VALIDODESTE: 'Válido desde',
	_VALIDOHASTA: 'Válido hasta',
	_TRANSITO: 'Tránsito',
	_FRECUENCIA: 'Frecuencia',
	_EJECUTIVOCOMERCIAL: 'Ejecutivo comercial',
	_TOTALUTILIDAD: 'Total utilidad',
	_TOTALCOMPRA: 'Total compra',
	_TOTALVENTA: 'Total venta',
};

@Component({
	selector: 'app-lista-cotizacion',
	templateUrl: './lista-cotizacion.component.html',
	styleUrls: ['./lista-cotizacion.component.scss'],
	providers: [VariableGlobalCotizacionService],
})
export class ListaCotizacionComponent extends ModalUtil<SacCotizacion> implements OnInit, OnDestroy {
	frameworkComponents = {
		cellEstadoRenderComponent: CellEstadoRenderComponent,
		iconAgGridRenderComponent: IconAgGridRenderComponent,
	};

	formGroup!: UntypedFormGroup;
	configButtonsAction: ConfigButtonAction = {};
	columnDefs: ColDef[] = [];
	accesos: SciAccion[] = [];
	codigoActions: (string | null | undefined)[] = [];
	gridApi?: GridApi;
	newtabs: Array<Window> = [];

	listaSacCotizaciones: SacCotizacion[] = [];

	subscription$ = new Subscription();
	filterConfig!: ConfigFilter;
	isNotificacion = true;

	rowData$: Observable<SacCotizacion[]> = of([]);
	permissions: string[] = [];

	private agGridActions: ConfigButtonAgGrid[] = [];

	constructor(
		private readonly sacCotizacionService: SacCotizacionService,
		private readonly _routerService: Router,
		private readonly _activatedRoute: ActivatedRoute,
		private readonly _matDialog: MatDialog,
		private readonly _changeDetectorRef: ChangeDetectorRef,
		private readonly _applicationRef: ApplicationRef,
		private readonly _storeService: StoreService,
		private readonly _notificationService: NotificationService,
		private readonly _sdoPrinterService: SdoPrinterService,
		private readonly _sdoTipoDocumentoService: SdoTipoDocumentoService,
		private readonly _filtroPorReferenciaService: FiltroPorReferenciaService,
		private readonly _cotizacionService: CotizacionService,
		private readonly _sacCotizacionTipoOperadorService: SacCotizacionTipoOperadorService,
		private readonly appStore: Store<AppState>,
		private readonly _cotizacionEstadoService: SacCotizacionEstadoService,
		private readonly _variableGlobalCotizacionService: VariableGlobalCotizacionService
	) {
		super(_matDialog, SolicitudCotizacionDialogComponent, sacCotizacionService, { width: '1200px' });
		this._selectVariableGlobal();
	}

	ngOnInit(): void {
		this.filterByNotification();
		this._initAgGrid();
		this._selectStore();
		this._updateListCotizacion();

		this._activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
			const dateForFilter = `01/${params.get('month') || ''}/${params.get('year') || ''}`;
			const lastDay = params.get('year') ? DateUtil.getLastDayFromMonth(dateForFilter) : '';

			this.filterConfig = {
				filtro: params.get('filtro') as EnumReferenceRoutingFilterControl,
				value: Number(params.get('value')),
				firstDate: dateForFilter,
				lastDate: lastDay ?? '',
			};
		});
	}

	private _selectVariableGlobal(): void {
		const isVariableGlobalUtilidadPositiva = this._variableGlobalCotizacionService.getVariableGlobalCotiUtilPosi();
		if (isVariableGlobalUtilidadPositiva) {
			this.agGridActions = [
				{
					actionCode: EnumButtonType.TYPE_EDIT,
					tooltipOption: { label: 'Modificar', classList: 'bg-warning' },
					iconOption: { classList: 'text-warning', icon: 'fa fa-pen' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.Editar),
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.Editar),
				},
				{
					actionCode: EnumAccion.Resumen,
					tooltipOption: { label: 'Resumen', classList: 'bg-primary' },
					iconOption: { classList: 'text-primary', icon: 'fas fa-eye' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.Resumen),
				},
				{
					actionCode: EnumAccion.FleteCargos,
					tooltipOption: { label: 'Flete/Cargos', classList: 'bg-success' },
					iconOption: { classList: 'text-success', icon: 'far fa-money-bill-alt' },
					hide: (data): boolean => {
						const totalUtilidad = Number(data?.data.totalUtilidad) || 0;
						const hasPermission = !this.permissions.includes(EnumAccion.EmitirCotizacion);
						return totalUtilidad < 0 && !hasPermission;
					},
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.FleteCargos),
				},
				{
					actionCode: EnumAccion.FleteCargos,
					tooltipOption: { label: 'Flete/Cargos', classList: 'bg-danger' },
					iconOption: { classList: 'text-danger', icon: 'far fa-money-bill-alt' },
					hide: (data): boolean => {
						const totalUtilidad = Number(data?.data.totalUtilidad) || 0;
						const hasPermission = !this.permissions.includes(EnumAccion.EmitirCotizacion);
						return totalUtilidad >= 0 && !hasPermission;
					},
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.FleteCargos),
				},
				{
					actionCode: EnumAccion.EmitirCotizacion,
					tooltipOption: { label: 'Emitir', classList: 'bg-primary' },
					iconOption: { classList: 'text-primary', icon: 'fa fa-print' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.EmitirCotizacion),
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.EmitirCotizacion),
				},
				{
					actionCode: '',
					tooltipOption: { label: 'Más acciones' },
					iconOption: { icon: 'fa fa-ellipsis-v', classList: 'text-black-50' },
					submenu: [
						{
							actionCode: EnumAccion.Copiar,
							label: 'Copiar cotización',
							icon: 'fa-regular fa-copy',
							iconClass: 'text-primary',
							hide: (): boolean => !this.permissions.includes(EnumAccion.Copiar),
						},
						{
							actionCode: EnumAccion.GenerarRo,
							icon: 'fas flaticon2-website',
							iconClass: 'text-success',
							label: 'Generar Orden',
							hide: (): boolean => !this.permissions.includes(EnumAccion.GenerarRo),
							disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.GenerarRo),
						},
						{
							actionCode: EnumAccion.Rechazar,
							icon: 'fa fa-ban',
							iconClass: 'text-danger',
							label: 'Rechazar',
							hide: (): boolean => !this.permissions.includes(EnumAccion.Rechazar),
							disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.Rechazar),
						},
						{
							actionCode: EnumButtonType.TYPE_DELETE,
							icon: 'fa fa-trash',
							iconClass: 'text-danger',
							label: 'Eliminar',
							hide: (): boolean => !this.permissions.includes(EnumPermissionDefault.Eliminar),
							disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.Eliminar),
						},
					],
				},
			];
		} else {
			this.agGridActions = [
				{
					actionCode: EnumButtonType.TYPE_EDIT,
					tooltipOption: { label: 'Modificar', classList: 'bg-warning' },
					iconOption: { classList: 'text-warning', icon: 'fa fa-pen' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.Editar),
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.Editar),
				},
				{
					actionCode: EnumAccion.Resumen,
					tooltipOption: { label: 'Resumen', classList: 'bg-primary' },
					iconOption: { classList: 'text-primary', icon: 'fas fa-eye' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.Resumen),
				},
				{
					actionCode: EnumAccion.FleteCargos,
					tooltipOption: { label: 'Flete/Cargos', classList: 'bg-success' },
					iconOption: { classList: 'text-success', icon: 'far fa-money-bill-alt' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.FleteCargos),
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.FleteCargos),
				},
				{
					actionCode: EnumAccion.EmitirCotizacion,
					tooltipOption: { label: 'Emitir', classList: 'bg-primary' },
					iconOption: { classList: 'text-primary', icon: 'fa fa-print' },
					hide: (): boolean => !this.permissions.includes(EnumAccion.EmitirCotizacion),
					disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.EmitirCotizacion),
				},
				{
					actionCode: '',
					tooltipOption: { label: 'Más acciones' },
					iconOption: { icon: 'fa fa-ellipsis-v', classList: 'text-black-50' },
					submenu: [
						{
							actionCode: EnumAccion.Copiar,
							label: 'Copiar cotización',
							icon: 'fa-regular fa-copy',
							iconClass: 'text-primary',
							hide: (): boolean => !this.permissions.includes(EnumAccion.Copiar),
						},
						{
							actionCode: EnumAccion.GenerarRo,
							icon: 'fas flaticon2-website',
							iconClass: 'text-success',
							label: 'Generar Orden',
							hide: (): boolean => !this.permissions.includes(EnumAccion.GenerarRo),
							disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.GenerarRo),
						},
						{
							actionCode: EnumAccion.Rechazar,
							icon: 'fa fa-ban',
							iconClass: 'text-danger',
							label: 'Rechazar',
							hide: (): boolean => !this.permissions.includes(EnumAccion.Rechazar),
							disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.Rechazar),
						},
						{
							actionCode: EnumButtonType.TYPE_DELETE,
							icon: 'fa fa-trash',
							iconClass: 'text-danger',
							label: 'Eliminar',
							hide: (): boolean => !this.permissions.includes(EnumPermissionDefault.Eliminar),
							disabled: ({ data }): boolean => this.disabledActionByState(data.sacTipoEstadoCotizacion?.codigo, EnumAccion.Eliminar),
						},
					],
				},
			];
		}
	}

	filterByNotification(): void {
		this._activatedRoute.params.subscribe({
			next: (e) => {
				if (e.nroCotizacion === undefined || e.nroCotizacion === null) {
					this.isNotificacion = true;
				} else {
					this.isNotificacion = false;
					setTimeout(() => {
						const anio = String(e.nroCotizacion).split('-')[0];
						const nroCotizacion = +String(e.nroCotizacion).split('-')[1];
						const searchCotizacio = new SearchSacCotizacion();
						searchCotizacio.ano = +anio;
						searchCotizacio.nroCotizacion = String(nroCotizacion);
						searchCotizacio.tipoFiltro = 'porReferencia';
						searchCotizacio.tipoReferencia = EnumNumeroCotizacion.NROCOTI.code;
						this.onFilterForReferencia(searchCotizacio);
					}, 1000);
				}
			},
		});
	}

	private _initAgGrid(): void {
		this.columnDefs = [
			{
				headerName: constHeadersListaCotizacion._NUMERO,
				field: 'nroCotizacion',
				width: 155,
				resizable: false,
				sortable: true,
				pinned: 'left',
				suppressMenu: true,
				lockPosition: true,
			},
			{
				headerName: constHeadersListaCotizacion._FECHA,
				field: 'fchCotizacion',
				width: 120,
				resizable: false,
				cellClass: ['d-flex justify-content-end'],
				valueFormatter(params: ValueFormatterParams): string {
					return params?.value ? DateUtil.formatDate(params?.value, 'DATE') : '';
				},
			},
			{
				headerName: constHeadersListaCotizacion._ESTADO,
				field: 'sacTipoEstadoCotizacion',
				cellStyle: { 'text-align': 'center', 'padding-left': 2, 'padding-right': 2 },
				cellRenderer: 'cellEstadoRenderComponent',
				pinned: 'left',
				resizable: false,
				lockPosition: true,
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TIPOOPERACION,
				field: 'tbTipoManifiesto.nombre',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._VIATRANSPORTE,
				field: 'tbViaTransporte.nombre',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TIPOFLETE,
				field: 'tbTipoMovimientoContenedor.nombre',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._OPERADOR,
				field: 'sacCotizacionTipoOperadores',
				valueGetter: (params: ValueGetterParamsCustom): SacCotizacionTipoOperador[] => {
					return params.data.sacCotizacionTipoOperadores ?? [];
				},
				cellRenderer: 'iconAgGridRenderComponent',
				cellStyle: { 'white-space': 'pre-line' },
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._INCOTERM,
				field: 'tbIncoterm.codigo',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._CLIENTE,
				field: 'tbCliente.tbEntidad.razonSocial',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._ORIGEN,
				field: 'tbUbicacionComercialEmbarque',
				valueGetter: (params: ValueGetterParamsCustom): string => params.data.tbUbicacionComercialEmbarque?.nombre + ' , ' + params.data.tbUbicacionComercialEmbarque?.tbPais?.nombre,
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._DESTINO,
				field: 'tbUbicacionComercialLlegada',
				valueGetter: (params: ValueGetterParamsCustom): string => params.data.tbUbicacionComercialLlegada?.nombre + ' , ' + params.data.tbUbicacionComercialLlegada?.tbPais?.nombre,
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._VALIDODESTE,
				field: 'fchVigenciaDesde',
				valueFormatter(params: ValueFormatterParams): string {
					const parsedDate = parseISO(params.value);
					return params?.value ? format(parsedDate, 'dd/MM/yyyy') : '';
				},
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._VALIDOHASTA,
				field: 'fchVigenciaHasta',
				valueFormatter(params: ValueFormatterParams): string {
					const parsedDate = parseISO(params.value);
					return params?.value ? format(parsedDate, 'dd/MM/yyyy') : '';
				},
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TRANSITO,
				field: 'tiempoTransito',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._FRECUENCIA,
				field: 'frecuenciaSalida',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._EJECUTIVOCOMERCIAL,
				field: 'tbEjecutivoVendedor',
				valueGetter: (params: ValueGetterParamsCustom): string =>
					params.data?.tbEjecutivoVendedor
						? params.data.tbEjecutivoVendedor?.tbPersona?.nombre + ' ' + params.data.tbEjecutivoVendedor?.tbPersona?.apellido1 + ' ' + params.data.tbEjecutivoVendedor?.tbPersona?.apellido2
						: '',
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TOTALCOMPRA,
				field: 'totalCompra',
				cellClass: ['d-flex justify-content-end'],
				valueFormatter(params: ValueFormatterParams): string {
					return parseDecimalAndCommaCustom(params.value as number, 3);
				},
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TOTALVENTA,
				field: 'totalVenta',
				cellClass: ['d-flex justify-content-end'],
				valueFormatter(params: ValueFormatterParams): string {
					return parseDecimalAndCommaCustom(params.value as number, 3);
				},
				width: 140,
			},
			{
				headerName: constHeadersListaCotizacion._TOTALUTILIDAD,
				field: 'totalUtilidad',
				cellClass: ['d-flex justify-content-end'],
				valueFormatter(params: ValueFormatterParams): string {
					return parseDecimalAndCommaCustom(params.value as number, 3);
				},
				width: 140,
				// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
				cellStyle: (params) => {
					if (params.value < 0) {
						return { color: 'red' };
					}
					return null;
				},
			},
		];
	}

	private _selectStore(): void {
		this.subscription$.add(
			this._storeService.currentActions$.subscribe(([actions]) => {
				this.permissions = actions;
				this.configButtonsAction = {
					agGrid: {
						actions: [/*defaultAction[0],*/ ...this.agGridActions],
					},
				};
			})
		);
	}

	private async _updateListCotizacion(): Promise<void> {
		//Actualizar solo estado
		this._notificationService.getObjectAppSync(EnumChanelAppSync.ListadoCotizacionEstado).subscribe((dataEstado) => {
			if (dataEstado) {
				const [data] = dataEstado;
				this._updateGridApi(data, null);
			}
		});
		//Actualizar tipo operador
		this._notificationService.getObjectAppSync(EnumChanelAppSync.ListadoCotizacionTipoOperador).subscribe((tipoOperador) => {
			if (tipoOperador) {
				const [data] = tipoOperador;
				this._updateGridApi(null, data);
			}
		});
	}

	//Actualiza la tabla
	private _updateGridApi(cotiEstado: SacCotizacion, cotiTipoOperador: SacCotizacion | null): void {
		this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: IRowNode) => {
			const rowCotizacion = rowNode?.data as SacCotizacion;
			if (rowCotizacion.idCotizacion === cotiEstado?.idCotizacion) {
				if (rowCotizacion.idTipoEstadoCotizacion !== cotiEstado.idTipoEstadoCotizacion) {
					rowCotizacion.idTipoEstadoCotizacion = cotiEstado.idTipoEstadoCotizacion;
					rowCotizacion.sacTipoEstadoCotizacion = cotiEstado.sacTipoEstadoCotizacion;
				}
			}
			if (rowCotizacion.idCotizacion === cotiTipoOperador?.idCotizacion) {
				if (cotiTipoOperador?.sacCotizacionTipoOperadores != null) {
					rowCotizacion.sacCotizacionTipoOperadores = [...cotiTipoOperador.sacCotizacionTipoOperadores];
				}
			}
			rowNode?.setData(rowCotizacion);
		});
		this._changeDetectorRef.markForCheck();
	}

	// private _updateListCotizacion(): void {
	// 	this._notificationService?.getUpdatedEstadoCotizacion()?.subscribe((data) => {
	// 		const payload = data['payload'];
	// 		const cotizacion = JSON.parse(payload?.toString()) as SacCotizacion;
	// 		this.gridApi?.forEachNodeAfterFilterAndSort((rowNode: IRowNode) => {
	// 			const rowCotizacion = rowNode?.data as SacCotizacion;
	// 			console.log('cotizacion', cotizacion);
	// 			if (rowCotizacion.idCotizacion === cotizacion?.idCotizacion) {
	// 				if (rowCotizacion.idTipoEstadoCotizacion !== cotizacion.idTipoEstadoCotizacion) {
	// 					rowCotizacion.idTipoEstadoCotizacion = cotizacion.idTipoEstadoCotizacion;
	// 					rowCotizacion.sacTipoEstadoCotizacion = cotizacion.sacTipoEstadoCotizacion;
	// 				}
	// 				if (cotizacion.sacCotizacionTipoOperadores != null) {
	// 					rowCotizacion.sacCotizacionTipoOperadores = [...cotizacion.sacCotizacionTipoOperadores];
	// 				}
	// 				rowNode?.setData(rowCotizacion);
	// 			}
	// 		});
	// 	});
	// }

	async onButtonAction(typeAction: string, actionCode: string, resource?: IRowNode<RowNode>): Promise<void> {
		const cotizacion: SacCotizacion = resource?.data as SacCotizacion;
		if (typeAction == 'ActionAgGrid') {
			switch (actionCode) {
				case 'ACC-FLETE-CARGOS':
					this._openFleteCargos(cotizacion);
					break;
				case 'ACC-VER-RESUMEN':
					this.actionAgGridTypeResum(cotizacion);
					break;
				case String(EnumAccion.EmitirCotizacion):
					this.actionAgGridTypeImprimirCotizacion(cotizacion);
					break;
				case String(EnumAccion.GenerarRo):
					this.actionAgGridTypeAperturaOrden(cotizacion);
					break;
				case String(EnumAccion.Copiar):
					this.actionAgGridTypeCopiarCotizacion(cotizacion);
					break;
				case String(EnumAccion.Rechazar):
					this.actionAgGridTypeRechazar(cotizacion);
					break;
			}
		}
	}

	get configObjectDlg(): ConfigObjectDialog {
		return { title: 'Cotización' };
	}
	get idNameToModify(): string {
		return '';
	}

	async actionAgGridTypeResum(sacCotizacion: SacCotizacion): Promise<void> {
		const data = new DialogData<number>();
		data.data = sacCotizacion.idCotizacion;
		const configDialog = { width: '1050px', height: '90vh', maxHeight: '90vh' };
		this.openDialogCustom(VerCotiResumenComponent, data, configDialog);
	}

	handlerAfterClosed(resp: { shouldRefreshList: boolean; cotizacion: SacCotizacion }): void {
		const { shouldRefreshList, cotizacion } = resp;
		if (shouldRefreshList) {
			this.onFilterSearchCotizaciones(this.changeFilterRo);
			this.onEditRow(cotizacion as IRowNode);
		}
	}

	async onEditRow(cotizacion: IRowNode<RowNode>): Promise<void> {
		const coti = cotizacion as SacCotizacion;
		const respEstados = await firstValueFrom(this._cotizacionEstadoService.findAllByIdCotizacionCustom(coti.idCotizacion));
		let isEstadoEnviado = false;
		if (respEstados) {
			isEstadoEnviado = respEstados.some((estado) => estado.codigo === EnumEstadoCotizacion.ENVIADA);
		}

		const serializeUrl =
			'#' +
			this._routerService.serializeUrl(
				this._routerService.createUrlTree([`./registro/${coti.idCotizacion}/${isEstadoEnviado}`], {
					relativeTo: this._activatedRoute,
				})
			);

		const windowName = `registro_coti_${coti.idCotizacion}_${isEstadoEnviado}`;

		const newTab = window.open(serializeUrl, windowName);
		if (newTab) this.openTab(newTab);
	}

	async onDeleteRow(gridApi: GridApi, rowNodes: SacCotizacion[]): Promise<void> {
		const cotizacion: SacCotizacion = rowNodes[0];
		this.messageUtilService.getMessageQuestion(`¿Desea eliminar la cotización N° ${cotizacion.nroCotizacion}?`, 'Esta acción no se puede deshacer').then((res) => {
			if (res.value) {
				this.subscription$.add(
					this.sacCotizacionService.deleteCotizacion(cotizacion).subscribe((e) => {
						this.onFilterSearchCotizaciones(this.changeFilterRo);
						this.messageUtilService.getAlertSuccessBasic(e.mensaje);
					})
				);
			}
		});
	}

	async onFilterSearchCotizaciones(search: SearchSacCotizacion): Promise<void> {
		this.gridApi?.showLoadingOverlay();
		this.sacCotizacionService.findListaCotizaciones(search).subscribe((data: SacCotizacion[]) => {
			this.gridApi?.setGridOption('rowData', data);
			this._autosizeGrid();
		});
	}

	onGridReady(gridApi: GridApi): void {
		this.gridApi = gridApi;
		this.gridApi.showLoadingOverlay();
	}

	private _autosizeGrid(): void {
		this.gridApi?.redrawRows();
	}

	// Filter - Events
	changeFilterRo!: SearchSacCotizacion;

	onFilterForReferencia(changeFilter: SearchSacCotizacion): void {
		this.changeFilterRo = changeFilter;
		this.onFilterSearchCotizaciones(changeFilter);
	}

	onFilterForCriterio(searchCriterioBusqueda: SearchSacCotizacion): void {
		if (this.isNotificacion) {
			this.changeFilterRo = searchCriterioBusqueda;
			this.onFilterSearchCotizaciones(searchCriterioBusqueda);
		} else {
			this.isNotificacion = true;
		}
	}

	// Flete/Cargos
	private _openFleteCargos(cotizacion: SacCotizacion): void {
		const serializeUrl =
			'#' +
			this._routerService.serializeUrl(
				this._routerService.createUrlTree([`./flete-cargo/${cotizacion.idCotizacion}`], {
					relativeTo: this._activatedRoute,
				})
			);

		const windowName = `flete_cargo_${cotizacion.idCotizacion}`;

		const newTab = window.open(serializeUrl, windowName);
		if (newTab) this.openTab(newTab, true);
	}

	openTab(newtab: Window, closeClickPestania = false): void {
		this.newtabs = this.newtabs.filter((tab: { name: string }) => tab.name !== '');
		if (this.newtabs.length > 0) {
			let isValid = false;
			this.newtabs.forEach((element) => {
				if (element == newtab) {
					isValid = true;
				}
			});
			if (!isValid) {
				this.newtabs.push(newtab);
			}
		} else {
			this.newtabs.push(newtab);
		}
		this.newtabs.forEach((window: Window) => {
			let isWindowClose = false;
			if (!window) return;
			if (!closeClickPestania) {
				window.addEventListener('unload', () => {
					if (isWindowClose) {
						this.onFilterSearchCotizaciones(this.changeFilterRo);
						const index = this.newtabs.findIndex((tab: Window) => tab === window);
						if (index !== -1) this.newtabs.splice(index, 1);
						this._applicationRef.tick();
						this._changeDetectorRef.markForCheck();
					}
					isWindowClose = true;
				});
			} else {
				window.addEventListener('unload', () => {
					this.onFilterSearchCotizaciones(this.changeFilterRo);
					const index = this.newtabs.findIndex((tab: Window) => tab === window);
					if (index !== -1) this.newtabs.splice(index, 1);
					this._applicationRef.tick();
					this._changeDetectorRef.markForCheck();
				});
			}
		});
	}

	async actionAgGridTypeImprimirCotizacion(cotizacion: SacCotizacion): Promise<void> {
		const coti = { ...cotizacion };
		const resp = await firstValueFrom(this._sdoTipoDocumentoService.findAllByCodigoSistema(coti));
		if (resp && resp?.length > 0) {
			if (resp.length > 1) {
				const dataResp = resp.filter((f) => f.idTipoDocumento != null && f.tbTipoFormato != null);
				//abrir modal
				if (dataResp) {
					const data = new DialogData<SdoTipoDocumento[]>();
					data.data = dataResp;
					data.title = 'Cotización';
					data.object = coti;
					this.openDialogCustom(PrintTipoDocumentoDialogComponent, data, { width: '500px' });
				} else {
					this.messageUtilService.getMessageInfoSmall('no existe plantilla asociada');
				}
			} else if (resp.length === 1) {
				try {
					showLoading('Generando documento...');
					const data: SdoPrinterCustom = {
						object: coti,
						idTipoDocumento: resp[0].idTipoDocumento,
						isPdf: false,
						idUsuario: this.getUserId(),
					};
					this.subscription$.add(
						this._sdoPrinterService.generateFomatos([data]).subscribe({
							next: (e) => {
								e.forEach((element) => {
									const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
									this._filtroPorReferenciaService.downloadBase64File(contentType, element.response, element.sdoTipoDocumentoDto?.plantilla);
								});
								hideLoading();
							},
							error: () => hideLoading(),
						})
					);
				} catch {}
			} else {
				this.messageUtilService.getMessageInfoSmall('no existe plantilla asociada');
			}
		} else {
			this.messageUtilService.getMessageInfoSmall('no existe documentos configurados');
		}
	}

	async actionAgGridTypeAperturaOrden(cotizacion: SacCotizacion): Promise<void> {
		if (!cotizacion) {
			return;
		}
		if (cotizacion.idCotizacion) {
			const listTipoOperadores: SacCotizacionTipoOperador[] = await firstValueFrom(this._sacCotizacionTipoOperadorService.findAllByIdCotizacion(cotizacion.idCotizacion));

			if (listTipoOperadores) {
				if (listTipoOperadores.length > 1) {
					const data = new DialogData<SacCotizacion>();
					data.data = cotizacion;
					data.title = 'Cotización';
					data.subTitle = 'Generar Orden';
					data.object = listTipoOperadores;
					this.openDialogCustom(AperturaOrdenDialogComponent, data, { width: '600px' });
				} else if (listTipoOperadores.length == 1) {
					if (listTipoOperadores[0].tbTipoOperador?.codigo == EnumTipoOperador.AGTE_CARGA) {
						await this._cotizacionService.aperturarScaRouting(cotizacion.idCotizacion, this.getUserId());
					}
				}
			}
		}
	}

	actionAgGridTypeCopiarCotizacion(coti: SacCotizacion): void {
		this.messageUtilService.getMessageQuestion(`¿Desea copiar la Cotización N° ${coti.nroCotizacion}?`, 'Esta acción no se puede deshacer').then((res) => {
			if (res.value) {
				this.copiarCotizacion(coti);
			}
		});
	}

	copiarCotizacion(coti: SacCotizacion): void {
		const isCostos = coti?.sacCotizacionConceptos && coti.sacCotizacionConceptos.length > 0;
		const nuevoEstado: string = isCostos ? 'PRICING' : 'SOLICITADA';
		this._cotizacionEstadoService.findAllEstados().subscribe({
			next: (estados) => {
				const estadoEncontrado = estados.find((estado) => estado.nombre === nuevoEstado);
				const newCoti = this._cleanCotizacionIds({
					...coti,
					idTipoEstadoCotizacion: estadoEncontrado?.idTipoEstadoCotizacion,
					fchVigenciaDesde: new Date(),
					fchVigenciaHasta: this.getFecha(),
				});
				this.insertCotizacion(newCoti);
			},
		});
	}

	getFecha(): Date {
		const fechaDesde = new Date();
		const diasSumados = this._variableGlobalCotizacionService.getVariableGlobalCantidadDiasVigenciaTarifa();

		if (diasSumados === null) {
			return new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);
		} else {
			const fechaConDiasSumados = new Date(fechaDesde);
			fechaConDiasSumados.setDate(fechaDesde.getDate() + diasSumados);
			return fechaConDiasSumados;
		}
	}

	insertCotizacion(coti: SacCotizacion): void {
		this.subscription$.add(
			this.sacCotizacionService.insertSolicitudCotizacion(coti).subscribe(() => {
				this.messageUtilService.getAlertSuccessBasic('Cotizacion copiada');
				this.onFilterSearchCotizaciones(this.changeFilterRo);
			})
		);
	}

	getUserId(): number {
		let idUsuario = 0;
		this.subscription$.add(
			this.appStore
				.select(currentUser)
				.pipe(
					tap((res) => {
						idUsuario = res?.id;
					})
				)
				.subscribe()
		);
		return idUsuario;
	}

	async actionAgGridTypeRechazar(cotizacion: SacCotizacion): Promise<void> {
		const data = new DialogData<SacCotizacion>();
		data.data = cotizacion;
		const respDialog = await this.openDialogCustom(RechazarCotizacionDialogComponent, data, { width: '500px' });
		if (respDialog) {
			this.messageUtilService.getAlertSucces('La cotización fue rechazada satisfactoriamente.', 'Rechazada');
		}
	}

	disabledActionByState(codEstado: string, enumEstado: EnumAccion): boolean {
		switch (enumEstado) {
			case EnumAccion.Editar:
				const listEnumEditar = [String(EnumEstadoCotizacion.ACEPTADA), String(EnumEstadoCotizacion.RECHAZADA)];
				return listEnumEditar.includes(codEstado);
			case EnumAccion.FleteCargos:
				const listFleteCargos = [String(EnumEstadoCotizacion.ACEPTADA), String(EnumEstadoCotizacion.RECHAZADA)];
				return listFleteCargos.includes(codEstado);
			case EnumAccion.EmitirCotizacion:
				const listEmitirCotizacion = [String(EnumEstadoCotizacion.SOLICITADA), String(EnumEstadoCotizacion.RECHAZADA)];
				return listEmitirCotizacion.includes(codEstado);
			case EnumAccion.GenerarRo:
				const listGenerarRo = [String(EnumEstadoCotizacion.ACEPTADA), String(EnumEstadoCotizacion.RECHAZADA)];
				return listGenerarRo.includes(codEstado);
			case EnumAccion.Rechazar:
				const listRechazar = [String(EnumEstadoCotizacion.ACEPTADA), String(EnumEstadoCotizacion.RECHAZADA)];
				return listRechazar.includes(codEstado);
			case EnumAccion.Eliminar:
				const listEliminar = [String(EnumEstadoCotizacion.ENVIADA), String(EnumEstadoCotizacion.ACEPTADA), String(EnumEstadoCotizacion.RECHAZADA)];
				return listEliminar.includes(codEstado);
			default:
				return false;
		}
	}

	ngOnDestroy(): void {
		this.subscription$.unsubscribe();
	}

	private _cleanCotizacionIds(cotizacion: SacCotizacion): SacCotizacion {
		cotizacion.idCotizacion = null;
		if (cotizacion.sacCotizacionContenedores) {
			cotizacion.sacCotizacionContenedores.forEach((contenedor) => {
				contenedor.idCotizacionContenedor = null;
			});
		}
		if (cotizacion.sacCotizacionTipoOperadores) {
			cotizacion.sacCotizacionTipoOperadores.forEach((operador) => {
				operador.idSistema = null;
				operador.idOrden = null;
				operador.nroReferencia = null;
				operador.fchReferencia = null;
			});
		}
		if (cotizacion.sacCotizacionDetalles) {
			cotizacion.sacCotizacionDetalles.forEach((detalle) => {
				detalle.idCotizacionDetalle = null;
				if (detalle.sacCotizacionDetalleBultos) {
					detalle.sacCotizacionDetalleBultos.forEach((bulto) => {
						bulto.idCotizacionDetalleBulto = null;
					});
				}
			});
		}
		if (cotizacion.sacCotizacionConceptos) {
			cotizacion.sacCotizacionConceptos.forEach((concepto) => {
				concepto.idCotizacionConcepto = null;
			});
		}
		return cotizacion;
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { find } from 'lodash-es';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, of, forkJoin } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SciOficina } from '~models/config';
import { TbCliente, TbConcepto, TbEjecutivo, TbEntidadContacto, TbIncoterm, TbTipoEnvio, TbTipoManifiesto, TbTipoOperador, TbTransportista, TbViaTransporte } from '~models/maestros';
import { SacTipoEstadoCotizacion } from '~models/maestros-sync/maestros/SacTipoEstadoCotizacion';
import { TbTipoMovimientoContenedor } from '~models/maestros-sync/maestros/TbTipoMovimientoContenedor';
import { SciOficinaService } from '~shared/services/sciOficina.service';
import { TbEjecutivoService } from '../maestros-sync/maestros/tbEjecutivo.service';
import { TbViatransporteService } from '../maestros-sync/maestros/tbViaTransporte.service';
import { TbIncotermService } from '../maestros-sync/maestros/tbIncoterm.service';
import { TbClienteService } from '../maestros-sync/maestros/tbCliente.service';
import { TbTipoManifiestoService } from '../maestros-sync/maestros/tbTipoManifiesto.service';
import { TbTipoOperadorService } from '../maestros-sync/maestros/tbTipoOperador.service';
import { SacTipoEstadoCotizacionService } from '../maestros-sync/acuerdos-comerciales/sacTipoEstadoCotizacion.service';
import { TbTipoMovimientoContenedorService } from '../maestros-sync/maestros/tbTipoMovimientoContenedor.service';
import { SearchSacCotizacion } from '~models/acuerdos-comerciales/custom/SearchSacCotizacion';
import { TbTipoEnvioService } from '../maestros-sync/maestros/tbTipoEnvio.service';
import { TbEntidadContactoService } from '../maestros/tbEntidadContacto.service';
import { TbTransportistaService } from '../maestros-sync/maestros/tbTransportista.service';
import { TbMoneda } from '~models/maestros-sync/maestros';
import { TbGrupoConceptoService, TbMonedaService } from '../maestros-sync/maestros';
import { TbNaturalezaCarga } from '~models/maestros-sync/maestros/TbNaturalezaCarga';
import { TbNaturalezaCargaService } from '../maestros-sync/maestros/tbNaturalezaCarga.service';
import { TbGrupoConcepto } from '~models/maestros-sync/maestros/TbGrupoConcepto';

@Injectable({
	providedIn: 'root',
})
export class SacCotizacionService extends GenericService<SacCotizacion, number> {
	private _url = `${environment.HOST_ACUERDO_COMERCIAL}/saccotizaciones`;

	constructor(
		protected _http: HttpClient,
		private sciOficinaService: SciOficinaService,
		private tbviatransporteService: TbViatransporteService,
		private tbEjecutivoService: TbEjecutivoService,
		private tbIncotermService: TbIncotermService,
		private tbClienteService: TbClienteService,
		private tbTipoManifiestoService: TbTipoManifiestoService,
		private tbTipoOperadorService: TbTipoOperadorService,
		private sacTipoEstadoCotizacionService: SacTipoEstadoCotizacionService,
		private tbTipoMovimientoContenedorService: TbTipoMovimientoContenedorService,
		private tbTipoEnvioService: TbTipoEnvioService,
		private tbEntidadContactoService: TbEntidadContactoService,
		private tbTransportistaService: TbTransportistaService,
		private tbGrupoConceptoService: TbGrupoConceptoService,
		private tbMonedaService: TbMonedaService,
		private tbNaturalezaCargaService: TbNaturalezaCargaService
	) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findById(id: number): Observable<SacCotizacion> {
		return this._http.get<ApiResponse>(`${this._url}/model-se-ro/${id}`).pipe(
			map((response) => {
				return response.data as SacCotizacion;
			})
		);
	}

	insertSolicitudCotizacion(sacCotizacion: SacCotizacion | null): Observable<ApiResponse> {
		return this._http.post<ApiResponse>(`${this._url}/insertSolicitudCotizacion`, sacCotizacion);
	}

	deleteCotizacion(sacCotizacion: SacCotizacion): Observable<ApiResponse> {
		return this._http.delete<ApiResponse>(`${this._url}`, { body: sacCotizacion });
	}

	findListaCotizaciones(search: SearchSacCotizacion): Observable<SacCotizacion[]> {
		return this._http.post<ApiResponse<SacCotizacion[]>>(`${this._url}/model-se-ro/findListaCotizaciones`, search).pipe(
			map((res: ApiResponse<SacCotizacion[]>) => res.data),
			mergeMap((cotizacion) => this.buscarData(cotizacion)),
			map((res) => this.llenarData(res))
		);
	}

	buscarData(cotizacion: SacCotizacion[]): Observable<
		[
			SacCotizacion[],
			SciOficina[],
			TbViaTransporte[],
			TbEjecutivo[],
			TbIncoterm[],
			TbCliente[],
			TbTipoManifiesto[],
			TbTipoOperador[],
			SacTipoEstadoCotizacion[],
			TbTipoMovimientoContenedor[],
			TbTipoEnvio[],
			TbEntidadContacto[],
			TbTransportista[],
			TbConcepto[],
			TbMoneda[],
			TbNaturalezaCarga[] //reporte
		]
	> {
		const idsOficina = cotizacion.map((d) => d.idOficina ?? 0);
		const idsViaTransportes = cotizacion.map((d) => d.idViaTransporte ?? 0);
		const idsEjecutivoVendedores = cotizacion.map((d) => d.idEjecutivoVendedor ?? 0);
		const idsIncoterm = cotizacion.map((d) => d.idIncoterm ?? 0);
		const idsClientes = cotizacion.map((d) => d.idCliente ?? 0);
		const idsTipoOperador = cotizacion.flatMap((d) => d.sacCotizacionTipoOperadores?.map((o) => o.idTipoOperador as number) ?? 0);
		const idsTipoManifiestos = cotizacion.map((d) => d.idTipoManifiesto ?? 0);
		const idsTipoEstadoCotizaciones = cotizacion.map((d) => d.idTipoEstadoCotizacion ?? 0);
		const idsTipoMovimientoContenedores = cotizacion.map((d) => d.idTipoMovimientoContenedor ?? 0);
		const idsTipoEnvios = cotizacion.map((d) => d.idTipoEnvio ?? 0);
		const idsEntidadContactoClientes = cotizacion.map((d) => d.idEntidadContactoCliente ?? 0);
		const idsTransportistas = cotizacion.map((d) => d.idTransportista ?? 0);
		const idsGrupoConcepto = cotizacion.flatMap((d) => d.sacCotizacionConceptos?.map((c) => c.idGrupoConcepto as number) ?? 0);
		const idsMoneda = cotizacion.flatMap((d) => d.sacCotizacionConceptos?.map((c) => c.idMoneda as number) ?? 0);
		const idsNaturalezaCarga = cotizacion.flatMap((d) => d.sacCotizacionDetalles?.map((c) => c.idNaturalezaCarga as number) ?? 0);

		return forkJoin([
			of(cotizacion),
			this.sciOficinaService.findAllByIdList(idsOficina),
			this.tbviatransporteService.findAllByIdList(idsViaTransportes),
			this.tbEjecutivoService.findAllByIdList(idsEjecutivoVendedores),
			this.tbIncotermService.findAllByIdList(idsIncoterm),
			this.tbClienteService.findAllByIdList(idsClientes),
			this.tbTipoManifiestoService.findAllByIdList(idsTipoManifiestos),
			this.tbTipoOperadorService.findAllByIdList(idsTipoOperador),
			this.sacTipoEstadoCotizacionService.findAllByIdList(idsTipoEstadoCotizaciones),
			this.tbTipoMovimientoContenedorService.findAllByIdList(idsTipoMovimientoContenedores),
			this.tbTipoEnvioService.findAllByIdList(idsTipoEnvios),
			this.tbEntidadContactoService.findAllByIdList(idsEntidadContactoClientes),
			this.tbTransportistaService.findAllByIdList(idsTransportistas),
			this.tbGrupoConceptoService.findAllByIdList(idsGrupoConcepto),
			this.tbMonedaService.findAllByIdList(idsMoneda),
			this.tbNaturalezaCargaService.findAllByIdList(idsNaturalezaCarga),
		]);
	}

	llenarData(
		res: [
			SacCotizacion[],
			SciOficina[],
			TbViaTransporte[],
			TbEjecutivo[],
			TbIncoterm[],
			TbCliente[],
			TbTipoManifiesto[],
			TbTipoOperador[],
			SacTipoEstadoCotizacion[],
			TbTipoMovimientoContenedor[],
			TbTipoEnvio[],
			TbEntidadContacto[],
			TbTransportista[],
			TbGrupoConcepto[],
			TbMoneda[],
			TbNaturalezaCarga[] //reporte
		]
	): SacCotizacion[] {
		const cotizaciones = res[0];
		const sciOficinas = res[1];
		const tbViaTransportes = res[2];
		const tbEjecutivoVendedores = res[3];
		const tbIncoterms = res[4];
		const tbClientes = res[5];
		const tbTipoManifiestos = res[6];
		const tbTipoOperadores = res[7];
		const sacTipoEstadoCotizaciones = res[8];
		const tbTipoMovimientoContenedores = res[9];
		const tbTipoEnvio = res[10];
		const tbEntidadContacto = res[11];
		const tbTransportistas = res[12];
		const tbGrupoConceptos = res[13];
		const tbMonedas = res[14];
		const tbNaturalezaCargas = res[15]; //reporte

		return cotizaciones.map((r) => {
			const sciOficina = find(sciOficinas, (t) => t.idOficina === r.idOficina);
			const tbViaTransporte = find(tbViaTransportes, (t) => t.idViaTransporte === r.idViaTransporte);
			const tbEjecutivoVendedor = find(tbEjecutivoVendedores, (t) => t.idEjecutivo === r.idEjecutivoVendedor);
			const tbIncoterm = find(tbIncoterms, (t) => t.idIncoterm === r.idIncoterm);
			const tbCliente = find(tbClientes, (t) => t.idCliente === r.idCliente);
			const tbTipoManifiesto = find(tbTipoManifiestos, (t) => t.idTipoManifiesto === r.idTipoManifiesto);
			const tbTipoEnvios = find(tbTipoEnvio, (t) => t.idTipoEnvio === r.idTipoEnvio);
			const tbEntidadContactos = find(tbEntidadContacto, (t) => t.idEntidadContacto === r.idEntidadContactoCliente);
			const tbTransportista = find(tbTransportistas, (t) => t.idTransportista === r.idTransportista);

			if (r.sacCotizacionTipoOperadores) {
				r.sacCotizacionTipoOperadores.forEach((e) => {
					const tipoOperador = tbTipoOperadores.find((c) => e.idTipoOperador === c.idTipoOperador);
					e.tbTipoOperador = tipoOperador ?? null;
				});
				r.sacCotizacionTipoOperadores.sort((a, b) => {
					return (a.tbTipoOperador?.idTipoOperador ?? 0) - (b.tbTipoOperador?.idTipoOperador ?? 0);
				});
			}

			const sacTipoEstadoCotizacion = find(sacTipoEstadoCotizaciones, (t) => t.idTipoEstadoCotizacion === r.idTipoEstadoCotizacion);
			const tbTipoMovimientoContenedor = find(tbTipoMovimientoContenedores, (t) => t.idTipoMovimientoContenedor === r.idTipoMovimientoContenedor);
			if (r.sacCotizacionConceptos) {
				r.sacCotizacionConceptos.forEach((e) => {
					const tbGrupoConcepto = tbGrupoConceptos.find((c) => e.idGrupoConcepto === c.idGrupoConcepto);
					const tbMoneda = tbMonedas.find((c) => e.idMoneda === c.idMoneda);
					e.tbGrupoConcepto = tbGrupoConcepto ?? null;
					e.tbMoneda = tbMoneda ?? null;
				});
			}
			if (r.sacCotizacionDetalles) {
				r.sacCotizacionDetalles?.forEach((e) => {
					const tbNaturalezaCarga = tbNaturalezaCargas.find((c) => c.idNaturalezaCarga === e.idNaturalezaCarga);
					e.tbNaturalezaCarga = tbNaturalezaCarga;
				});
			}

			if (sciOficina) r.sciOficina = sciOficina;
			if (tbViaTransporte) r.tbViaTransporte = tbViaTransporte;
			if (tbEjecutivoVendedor) r.tbEjecutivoVendedor = tbEjecutivoVendedor;
			if (tbIncoterm) r.tbIncoterm = tbIncoterm;
			if (tbCliente) r.tbCliente = tbCliente;
			if (tbTipoManifiesto) r.tbTipoManifiesto = tbTipoManifiesto;
			if (sacTipoEstadoCotizacion) r.sacTipoEstadoCotizacion = sacTipoEstadoCotizacion;
			if (tbTipoMovimientoContenedor) r.tbTipoMovimientoContenedor = tbTipoMovimientoContenedor;
			if (tbTipoEnvios) r.tbTipoEnvio = tbTipoEnvios;
			if (tbEntidadContactos) r.tbEntidadContactoCliente = tbEntidadContactos;
			if (tbTransportista) r.tbTransportista = tbTransportista;
			return r;
		});
	}

	countByIdCotizacionAndCodUnidadMedida(idCotizacion: number, codUnidadMedida: string): Observable<number> {
		return this._http.get<ApiResponse<number>>(`${this._url}/countByIdCotizacionAndCodUnidadMedida/${idCotizacion}/${codUnidadMedida}`).pipe(map((resp) => resp.data));
	}
}

import { Injectable } from '@angular/core';
import { CotizacionStore } from './registro-cotizacion/store/cotizacion.store';
import { TbParametro } from '~models/maestros-sync/maestros/TbParametro';
import { environment } from '../../../../environments/environment';
import { TbParametroLocalService } from '../../../services/maestros';

export interface VariableGlobalCotizacion {
	CD_PUER_MARI?: string;
	CD_PUER_AERO?: string;
	CD_PUER_TERR?: string;
	CD_COTI_UTIL_POSI?: boolean;
	CD_DIAS_VIGE_TARIFA?: number;
}

@Injectable({
	providedIn: 'root',
})
export class VariableGlobalCotizacionService {
	private readonly _codigoSistema = `${environment.COD_SISTEMA}`;
	private readonly _defaultVariables: VariableGlobalCotizacion = {
		CD_PUER_MARI: 'PECLL',
		CD_PUER_AERO: 'PELIM',
		CD_PUER_TERR: 'PELIM',
		CD_COTI_UTIL_POSI: true,
		CD_DIAS_VIGE_TARIFA: 15,
	};

	constructor(private readonly _store: CotizacionStore, private readonly _parametroLocalService: TbParametroLocalService) {
		this._loadGlobalVariables();
	}

	private _loadGlobalVariables(): void {
		const codigoSistema = this._codigoSistema;
		const codigoList = ['CD_PUER_MARI', 'CD_PUER_AERO', 'CD_PUER_TERR', 'CD_COTI_UTIL_POSI', 'CD_DIAS_VIGE_TARIFA'];

		this._parametroLocalService.findAllByCodigoSistemaAndCodigoList(codigoSistema, codigoList).subscribe({
			next: (response) => {
				const mappedVariables = this._mapParametrosToVariables(response);
				this._store.setVariableGlobalCotizacion(mappedVariables);
			},
			error: (err) => {
				console.error('Error al cargar las variables globales:', err);
				// Si hay error, se inicializa con los valores por defecto.
				this._store.setVariableGlobalCotizacion(this._defaultVariables);
			},
		});
	}

	private _mapParametrosToVariables(parametros: TbParametro[]): VariableGlobalCotizacion {
		const variables: VariableGlobalCotizacion = { ...this._defaultVariables };

		parametros.forEach((param) => {
			switch (param.codigo) {
				case 'CD_PUER_MARI':
					variables.CD_PUER_MARI = param.valor || this._defaultVariables.CD_PUER_MARI;
					break;
				case 'CD_PUER_AERO':
					variables.CD_PUER_AERO = param.valor || this._defaultVariables.CD_PUER_AERO;
					break;
				case 'CD_PUER_TERR':
					variables.CD_PUER_TERR = param.valor || this._defaultVariables.CD_PUER_TERR;
					break;
				case 'CD_COTI_UTIL_POSI':
					variables.CD_COTI_UTIL_POSI = param.valor === 'true';
					break;
				case 'CD_DIAS_VIGE_TARIFA':
					variables.CD_DIAS_VIGE_TARIFA = parseInt(param.valor, 10) || this._defaultVariables.CD_DIAS_VIGE_TARIFA;
					break;
				default:
					break;
			}
		});

		return variables;
	}

	getVariableGlobalCotizacion(): VariableGlobalCotizacion | null {
		return this._store.getVariableGlobalCotizacion();
	}

	getVariableGlobalPuertoMaritimo(): string | null {
		return this._store.getVariableGlobalCotizacion()?.CD_PUER_MARI ?? null;
	}

	getVariableGlobalCotiUtilPosi(): boolean | null {
		return this._store.getVariableGlobalCotizacion()?.CD_COTI_UTIL_POSI ?? null;
	}

	getVariableGlobalCantidadDiasVigenciaTarifa(): number | null {
		return this._store.getVariableGlobalCotizacion()?.CD_DIAS_VIGE_TARIFA ?? null;
	}
}
